@import 'src/design-system/styles/typography.mixins';

.root {
  @include font-style-small($font-weight-bold);
  position: absolute;
  top: 25px;
  left: 627px;
  width: 123px;
  height: 22px;
  text-align: center;
  color: var(--static-black);
  transform: rotate(45deg);
  text-transform: uppercase;
  background-color: var(--static-secondary);
}
