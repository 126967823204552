@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';
@import 'src/design-system/styles/layout-variables';

.container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: $content-indent;

  & h4 {
    @include font-style-h4($font-weight-medium);
    margin: 0;
  }
}

.promoWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 0;
  width: 100%;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

.promoContentItem {
  overflow: hidden;
  position: relative;
}
