@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/typography.variables';

.bannerContainer {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  height: 305px;
}

.bannerImage {
  position: absolute;
  top: 0;
  left: 50%;

  min-width: 100% !important;
  height: 100%;
  object-fit: cover;
  transform: translateX(-50%);
  z-index: -1;
}

.bannerContent {
  width: 100%;
  height: 100%;

  padding: 20px 24px;
}

.bannerContentWithText {
  background: linear-gradient(90deg, rgba(0, 0, 0, .8) 17.37%, rgba(0, 0, 0, .608) 50.62%, rgba(0, 0, 0, .28664) 92.66%);
}

.description {
  @include font-style-h3($font-weight-regular);
  padding-top: 16px;
}

.onlyDescription {
  padding-top: 0;
}

.title {
  @include font-style-h3;
}

.title,
.description {
  color: var(--static-white);

  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  max-width: 452px;
  margin: 0;
}

:global(.carousel .slide) {
  padding: 0 1px;
}
